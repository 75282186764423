.projects-nav {
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  overflow-y: scroll;
  /* margin-top: calc(-1* clamp(10px, 4vmin, 40px)); */
  padding-top: 0;
  margin-top: 0;
}

.project-summary {
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 0;
  text-align: right;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 1rem;
  font-weight: 300;
  color: #ffffff;
}

.project-title {
  text-align: right;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 2.875rem;
  margin-bottom: 3px;
  margin-top: 0;
  padding-top: 0;
  color: #ffffff;
}

.project-links {
    padding-top: 0;

}

a:hover .project-title,
a:hover .project-summary {
  color: #757575;
}

.projects-nav::-webkit-scrollbar {
  display: none;
}

.projects-nav a {
    margin-top: 0;
    margin-bottom: clamp(10px, 4vmin, 40px);
}

.project-nav-internal{
  position: relative;
  margin-bottom: clamp(10px, 4vmin, 40px);
  padding-bottom: 20px;
}

.project-linker {
  position: absolute;
  z-index: 12;
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 3px;
  background-color: #00000000;
  color: #ffffff;
  border: none;
  text-align: right;
  cursor: pointer;
  margin-top: -35px;
  font-weight: 300;
  font-size: 0.85rem;
  bottom: 0;
  right: 0;
}
.project-linker:hover {
  color: #757575;
}
