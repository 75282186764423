.project5-container {
  height: calc(100% - (165 + 6 * clamp(10px, 4vmin, 40px)));
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-column-gap: clamp(10px, 4vmin, 40px);
  overflow-y: hidden;
  padding-bottom: clamp(10px, 4vmin, 40px);
  /* border: solid 0.1px #ffd900; */
}

.grid1-5 {
  height: 100%;
  overflow-y: scroll;
}

.grid2-5 {
  height: 100%;
  overflow-y: scroll;
}

.grid1-5::-webkit-scrollbar {
  display: none;
}

.grid2-5::-webkit-scrollbar {
  display: none;
}

.section-title {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.375rem;
  font-weight: 300;
}

.horizontal-navbar .navbar ul{
  display: flex;
  flex-direction: row;
  gap: clamp(10px, 4vmin, 40px);
  max-width: 450px;
}

@media (max-width: 2140px) {
    .project5-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    overflow-y: scroll;
  }
    .project5-container::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 1080px) {
  .project5-container {
    min-width: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    overflow-y: scroll;
  }
  .project5-container::-webkit-scrollbar {
    display: none;
  }
  .grid1-5 {
    min-width: 0;
    height: 100%;
    overflow-y: visible;
  }

  .grid2-5 {
    min-width: 0;
    height: 100%;
    overflow-y: visible;
  }
}