* {
font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* color: #ffffff; */
  text-decoration: none;
  /* border: solid 0.1px #ff343480; */
}

body {
  overflow: hidden;
  background-color: #000000;
  color: #ffffff;
  width: 100%;
  height: 100lvh;
  /* padding: clamp(10px, 4vmin, 40px); */
}

.animation-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.35);
  background: url(data:;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAABl0RVh0U29mdHdhcmUAUGFpbnQuTkVUIHYzLjUuNUmK/OAAAAATSURBVBhXY2RgYNgHxGAAYuwDAA78AjwwRoQYAAAAAElFTkSuQmCC)
    repeat scroll transparent\9;
  /* ie fallback png background image */
  z-index: -1;
  color: white;
  backdrop-filter: blur(1px);
}

.project-overlay-change {
  background-color: rgba(0, 0, 0, 0.90);
}

.square {
  border: solid 1px #ffffff;
  position: absolute;
  width: calc(100% - 2 * clamp(10px, 4vmin, 40px));
  height: calc(100% - 2 * clamp(10px, 4vmin, 40px));
  opacity: 1;
  z-index: 0;
  padding-left: clamp(10px, 4vmin, 40px);
  padding-right: clamp(10px, 4vmin, 40px);
  margin-top: clamp(10px, 4vmin, 40px);
  margin-left: clamp(10px, 4vmin, 40px);
  display: flex;
  flex-direction: column;
  /* gap: clamp(10px, 4vmin, 40px); */
  overflow-y: hidden;
  overflow-x: hidden;
  box-sizing: border-box;
  top: 0;
  left: 0;
}

.border-square {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: clamp(10px, 4vmin, 40px) solid rgb(13, 13, 13);
  z-index: 0;
}

.intro-splash {
  position: absolute;
  height: 100lvh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -3;
}

.auth-name {
  font-family: 'Montserrat', sans-serif;
  animation-name: fadeOut;
  animation-duration: 0.3s;
  animation-timing-function: ease-in;
  animation-delay: 0.9s;
  animation-fill-mode: forwards;
  font-size: 1.875rem;
  font-weight: 300;
}

.auth-folio {
  font-family: 'Montserrat', sans-serif;
  animation-name: fadeOut;
  animation-duration: 0.3s;
  animation-timing-function: ease-in;
  animation-delay: 1.1s;
  animation-fill-mode: forwards;
  font-size: 1.875rem;
  font-weight: 300;
}

/* causing weird line flickering*/
.App {
  animation-name: fadeIn;
  animation-duration: 2.2s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.author-title {
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 2.875rem;
  font-weight: 300;
  padding-bottom: 0;
  margin-bottom: 0px;
  margin-top: calc(clamp(10px, 4vmin, 40px) - 13px);
}

.author-descriptor {
  padding-top: 0;
  margin: 0;
  font-size: 1rem;
  font-weight: 300;
}

.navbar {
  /* margin-top: calc(-1 * clamp(10px, 4vmin, 40px)); */
  z-index: 1;
}

.navbar ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  justify-content: space-between;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.navbar li {
  list-style: none;
  /* padding-bottom: 40px; */
  margin-top: clamp(10px, 4vmin, 40px);
}

.nav-link {
  text-decoration: none;
  font-size: 1.125rem;
  font-weight: 300;
  color: #ffffff;
}
.navbar .li-contact {
  padding-bottom: clamp(10px, 4vmin, 40px);
}

.nav-link:hover {
  color: #757575;
}

.permanent-elements {
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  gap: 3px;
}

canvas {
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 1;
  z-index: -2;
  margin: 0;
  padding: clamp(10px, 4vmin, 40px);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
