.project4-container {
  height: calc(100% - (165 + 6 * clamp(10px, 4vmin, 40px)));
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-column-gap: clamp(10px, 4vmin, 40px);
  overflow: hidden;
  padding-bottom: clamp(10px, 4vmin, 40px);
}

.plotly-hover-info {
  z-index: 9999;
}

.grid1-4 {
  height: 100%;
  overflow-y: scroll;
}

.grid1-4::-webkit-scrollbar {
  display: none;
}

.scroll-background {
  width: 0px;
  height: 0px;
}

.price-table-container{
  width: 200px;
  height: 91px;
}

.grid2-4 {
  height: 100%;
  overflow-y: scroll;
  grid-column-start: 2;
  grid-column-end: 3;
    /* align first item in column 2 with last item in column 1 */
  grid-row-start: 2;
  /* shift entire column 2 up to align with column 1 */
  grid-row-end: -1;
}

.grid2-4::-webkit-scrollbar {
  display: none;
}

.grid2-4 .hoverlayer {
  visibility: visible;
}

.modebar-container {
  visibility: hidden;
}

.project4-container .section-title {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.375rem;
  font-weight: 300;
}

.scatterplot-airline-container {
  margin-left: 0px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.line-chart-container {
  margin-top: 10px;
  margin-bottom: -45px;
}

.price-table-container {
  margin-top: 5px;
  margin-bottom: 0px;
} 

.horizontal-navbar .navbar ul{
  display: flex;
  flex-direction: row;
  gap: clamp(10px, 4vmin, 40px);
  max-width: 450px;
}

@media (max-width: 2140px) {
  .project4-container {
    min-width: 0;
    display: grid;
    grid-column-gap: clamp(10px, 4vmin, 40px);
    grid-template-columns: 1fr 3fr;
    overflow-y: scroll;
  }

   .grid2-4::-webkit-scrollbar {
    display: none;
  }

  .project4-container::-webkit-scrollbar {
    display: none;
  }

}

@media (max-width: 1550px) {
  .project4-container {
    min-width: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    overflow-y: scroll;
  }
  
  .project4-container::-webkit-scrollbar {
    display: none;
  }

  .grid1-4 {
    min-width: 0;
    height: 100%;
    overflow-y: visible;
  }
  .grid2-4 {
    min-width: 0;
    height: 100%;
    overflow-y: visible;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;
  }
}