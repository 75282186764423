.home-container {
  overflow-y: scroll;
}

.home-container::-webkit-scrollbar {
  display: none;
}

.author-summary {
  width: 30%;
  position: absolute;
  bottom: clamp(10px, 4vmin, 40px);
  right: clamp(10px, 4vmin, 40px);
  text-align: right;
  overflow-y: scroll;
}

.author-summary::-webkit-scrollbar {
  display: none;
}

@media (max-width:1079px){
  .author-summary {
    padding-top: clamp(10px, 4vmin, 40px);
    display: flex;
    text-align: left;
    position: relative;
    width: 100%;
    margin-right: 0;
    padding-right: 0;
    padding-left: clamp(10px, 4vmin, 40px);
    overflow-y: scroll;
  }
}